<template>
  <div>
    <Breadcrumbs>
      <router-link to="/home/contract-information">
        {{ $t("title.listOfContracts") }}
      </router-link>
      >
      {{ $route.meta.title }}
    </Breadcrumbs>
    <div class="form-container px-5 pt-3">
      <v-form class="px-2">
        <v-radio-group class="pb-11" v-model="active">
          <div v-for="(item, index) in items" v-bind:key="item.id">
            <div
              class="pb-2 pt-3 px-4 d-flex border-bold bg-white --border-border rounded-10"
            >
              <v-radio></v-radio>
              <span class="pl-1 --gray-dark">{{ item.text1 }}</span>
              <span class="--prm-light --w-bold">{{ item.text2 }}</span>
            </div>

            <div v-if="index === 0" class="text-medium px-1 pt-3 pb-6 --gray-dark">
              {{ nextMonthEndDate }}{{ item.desc }}
            </div>
            <div v-if="index === 1" class="text-medium px-1 pt-3 --gray-dark">
              {{ endDate }}{{ item.desc }} {{ nextMonthEndDate }}{{ item.desc2 }}
            </div>
          </div>

          <v-divider class="my-2"/>
        </v-radio-group>
        <div v-if="errorMessage()" class="text-medium --d-red pb-5 px-1">
          {{ nextMonthEndDate }}{{ $t("text.youCanNotAutoRenew") }}
        </div>
        <v-btn
          elevation="0"
          @click="change(active)"
          class="text-title --w-bold mb-10 --white"
          rounded
          block
          x-large
          color="bg-primary-light"
        >
          {{ $t('buttons.save') }}
        </v-btn>
      </v-form>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  name: 'Confirmation',
  components: {
    Breadcrumbs
  },
  async mounted () {
    await this.$doLoading(async () => {
      await this.$showGqlError(async () => {
        const data = await this.$store.dispatch('myVsContract', {
          apollo: this.$apollo
        })

        this.contract = data
        this.id = data.id
        this.active = data.autoRenewal ? 0 : 1
      })
    })
  },
  methods: {
    async change (index) {
      await this.$doLoading(async () => {
        await this.$showGqlError(async () => {
          await this.$store.dispatch('updateVsContract', {
            apollo: this.$apollo,
            autoRenewal: index === 0,
            contractId: this.id
          })
          await this.$router.push('/home/contract-information/')
        })
      })
    },
    nextMonth () {
      return parseInt(this.contract.endDate.slice(5, 7)) + 1
    },
    errorMessage () {
      return !!(this.contract.autoRenewal === true &&
        this.contract.canCancelAutoRenewal === false)
    }
  },
  computed: {
    nextMonthEndDate () {
      if (!this.contract.endDate) return ''
      return (parseInt(this.contract.endDate.slice(2, 4)) +
          (this.nextMonth() === 13 ? 1 : 0)) + this.$t('common.year') +
        (this.nextMonth() === 13 ? 1 + this.$t('common.month') : this.nextMonth() + this.$t('common.month'))
    },
    endDate () {
      if (!this.contract.endDate) return ''
      return this.contract.endDate.slice(2, 4) + this.$t('common.year') +
        this.contract.endDate.slice(5, 7) + this.$t('common.month') +
        this.contract.endDate.slice(8, 10) + this.$t('common.day')
    }
  },
  data () {
    return {
      active: '',
      contract: {},
      id: '',
      items: [{
        text1: this.$t('common.makeContract'),
        text2: this.$t('common.autoUpdate'),
        desc: this.$t('text.annualFeeWillBeCharged'),
        desc2: ''
      },
      {
        text1: this.$t('common.makeContract'),
        text2: this.$t('common.noAutoUpdate'),
        desc: this.$t('text.membershipWillBeAutomatically'),
        desc2: this.$t('text.noteThatReservationsMade')
      }
      ]
    }
  }
}
</script>
